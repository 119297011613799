/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import NextError from 'next/error'
import { useCatchError } from '@/shared/hooks'
import { ErrorsMessages } from '@/shared/constants'

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useCatchError(error)

  return (
    <html lang="es">
      <body>
        <NextError
          statusCode={undefined as any}
          title={ErrorsMessages.SomethingWentWrong}
        />
      </body>
    </html>
  )
}
